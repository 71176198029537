import styled from '@emotion/styled';
import { ThemeInterface } from '~/utils/themes';

interface ButtonProps {
    theme: ThemeInterface;
}

const Button = styled.button(({ theme }: ButtonProps) => `
    appearance: none;
    position: relative;
    display: inline;
    cursor: pointer;
    margin: 0;
    padding: 1px 0 0;
    line-height: 1;
    background: none;
    border: none;
    font-family: inherit;
    outline: none;
    text-transform: uppercase;
    font-size: inherit;
    color: rgba(0, 0, 0, 1);
    color: rgba(${theme.textRGB}, 1);

    &::after {
        position: absolute;
        left: 0;
        top: 100%;
        content: "";
        width: 100%;
        height: 2px;
        background: currentColor;
        transition: top 150ms ease-in-out;
        will-change: transform;
    }

    &:hover {
        color: rgba(0, 0, 0, 1);
        color: rgba(${theme.textRGB}, 1);
    }

    &:disabled,
    &:disabled:hover {
        color: rgba(0, 0, 0, 1);
        color: rgba(${theme.textRGB}, 1);
        cursor: not-allowed;

        &::after {
            top: 5px;
        }
    }
`);

export default Button;
