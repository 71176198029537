import React, {
    useState, /* useContext, */ useEffect, useCallback, useContext,
} from 'react';
import styled from '@emotion/styled';
import NewsArticle from '~/components/NewsArticle';
import SEO from '~/components/Seo';
// import { ThemeContext } from '~/context/ThemeContext';
import { NEWS_PAGE } from '~/utils/constants';
import {
    breakpoints,
    PageHeader, PageHeadline, Section, SingleSection,
} from '~/utils/styles';
// import themes from '~/utils/themes';
import { GraqhQlNodesInterface, NewsArticleInterface } from '~/utils/types';
import { fetchShopifyArticles } from '~/utils/api';
import { extractNodes } from '~/utils';
import Button from '~/components/Button';
import Footer from '~/components/Footer';
import { ThemeContext } from '~/context/ThemeContext';

// interface ArticleNodeInterface {
//     node: NewsArticleInterface;
// }

// interface DataProps {
//     data: {
//         storeFront: {
//             articles: {
//                 edges: [ArticleNodeInterface];
//             }
//         }
//     }
// }

const ARTICLES_PER_PAGE = 20;

const NewsPageSection = styled(SingleSection)`
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
`;

const NewsArticles = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: -3rem -1.5rem;
    box-sizing: border-box;
`;

const NewsArchiveArticleWrapper = styled.div`
    max-width: 615px;
    padding: 3rem 1.5rem;
    box-sizing: border-box;

    @media ${breakpoints.lg} {
         width: 50%;
    }
    
    // @media ${breakpoints.xl} {
    //     width: ${100 / 3}%;
    // }
`;

const NewsPageHeadline = styled(PageHeadline)(() => `
    font-size: 1.25rem;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.02em;

    @media ${breakpoints.lg} {
        font-size: 2rem;
    }
`);

const Pagination = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-top: 2px solid;
    text-align: center;
    max-width: 615px;
    margin: 95px auto 0;
    text-transform: uppercase;
    font-weight: 500;
`;

const NewsPage = () => {
    const { setTheme } = useContext(ThemeContext);
    const [mounted, setMounted] = useState(true);
    const [pageData, setPageData] = useState({
        hasPreviousPage: false,
        hasNextPage: false,
    });
    const [articlesData, setArticlesData] = useState<GraqhQlNodesInterface<NewsArticleInterface>>();
    const [error, setError] = useState(false);

    // let articles: NewsArticleInterface[] = [];

    // if (storeFront.articles) {
    //     articles = extractNodes(storeFront.articles);
    // }

    const fetchArticles = useCallback(async (params: {
        before?: string | undefined;
        after?: string | undefined;
    } = {}) => {
        try {
            const data = await fetchShopifyArticles({
                numArticles: ARTICLES_PER_PAGE,
                before: params?.before,
                after: params?.after,
            });

            if (data?.articles && mounted) {
                setPageData(data.articles.pageInfo);
                setArticlesData(data.articles);
            }
        } catch {
            setError(true);
        }
    }, [mounted]);

    useEffect(() => {
        setTheme(NEWS_PAGE.theme);
    }, [setTheme]);

    useEffect(() => {
        setMounted(true);
        fetchArticles();

        return () => setMounted(false);
    }, [fetchArticles]);

    const getNextPage = () => {
        const { edges } = articlesData || {};
        const last = edges?.[edges.length - 1];
        const { cursor } = last || {};

        if (cursor) {
            fetchArticles({
                after: cursor,
            });
        }
    };

    const getPreviousPage = () => {
        const { edges } = articlesData || {};
        const [first] = edges || [];
        const { cursor } = first || {};

        if (cursor) {
            fetchArticles({
                before: cursor,
            });
        }
    };

    const articles = articlesData ? extractNodes(articlesData) : [];

    return (
        <>
            <NewsPageSection>
                <SEO title="News" description="SLIC News Archive" />

                <PageHeader>
                    <NewsPageHeadline>
                        News
                    </NewsPageHeadline>
                </PageHeader>

                {error && (
                    <Section>
                        <div css={{ textAlign: 'center' }}>
                            An error occurred while fetching articles.
                        </div>
                    </Section>
                )}

                <NewsArticles>
                    {articles.map((article) => (
                        <NewsArchiveArticleWrapper
                            key={article.id}
                        >
                            <NewsArticle
                                article={article}
                                displayDate
                                displayAsCard
                            />
                        </NewsArchiveArticleWrapper>
                    ))}
                </NewsArticles>

                {pageData && (pageData.hasPreviousPage || pageData.hasNextPage) && (
                    <Pagination>
                        <Button
                            type="button"
                            theme={NEWS_PAGE.theme}
                            disabled={!pageData.hasPreviousPage}
                            onClick={getPreviousPage}
                        >
                            Newer
                        </Button>

                        {' '}

                        <Button
                            type="button"
                            theme={NEWS_PAGE.theme}
                            disabled={!pageData.hasNextPage}
                            onClick={getNextPage}
                        >
                            Older
                        </Button>
                    </Pagination>
                )}
            </NewsPageSection>

            <Footer />
        </>
    );
};

export default NewsPage;
